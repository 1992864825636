<template>
  <div>
    <sub-head v-bind:subTitle="content['회사 소개']"></sub-head>
    <div class="page_padding">
      <div class="d-flex justify-content-between paddingTB company_info">
        <div class="info_text width45 text-left">
          <div class="paddingTB1">
            <span
              class="fontBold font41 text333"
              style="border-bottom:3px solid #dc375d; padding: 0.2rem 0"
              >스프링소프트 소개</span
            >
          </div>
          <div class="word_break">
            <div class="padding2-5">
              <span class="fontM">
                “따뜻한 봄날같은 소프트웨어“ 를 목표로 하는
                <span class="textPink">디지털 헬스케어 소프트웨어 회사</span>
              </span>
            </div>
            <div>
              <span class="fontS"
                >㈜스프링소프트는 4차 산업혁명 시대의 디지털 시니어 계층의
                새로운 여가활동 및 인지능력 향상을 위한 플랫폼 리더가 되겠다는
                목표로 2019년 “해피테이블＂프로젝트를 기점으로 역량과 경험을
                축적하고 있는 회사 입니다.
              </span>
            </div>
            <div class="verticalSpacer-half"></div>
          </div>
        </div>
        <div class="info_img imgWrap width45">
          <img src="../../assets/source/images/company_introduction.png" />
        </div>
      </div>
    </div>
    <div class="history_background">
      <div class="">
        <div class="" style="padding:4rem 0">
          <div class="textWhite word_break text-center history_header">
            <span class="word_break fontExtraBold font41">연 혁</span>
          </div>
          <div class="verticalSpacer"></div>
          <div class="imgWrap history_line">
            <div
              style="position:absolute; left:50%"
              class="history-vertical-line"
            >
              <img src="../../assets/source/images/img_history.png" />
            </div>
          </div>

          <div class="verticalSpacer"></div>
          <div v-for="(item, index) in companyHistory" :key="index">
            <div
              class="paddingTB1-5"
              :class="[
                {history: index % 2 == 0},
                {history_reverse: index % 2 == 1},
              ]"
            >
              <div class="history_body">
                <div class="year_title">
                  <span class="fontExtraBold font38 textWhite">{{
                    item.year
                  }}</span>
                </div>
                <div class="verticalSpacer-half"></div>
                <div v-for="(content, index) in item.content" :key="index">
                  <div class="d-flex history_content">
                    <div>
                      <span class="fontS fontExtraBold textWhite">{{
                        content.month
                      }}</span>
                    </div>
                    <div>
                      <span
                        class="fontS fontMedium word_break"
                        style="color:#aaacc7"
                        >{{ content.title }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="verticalSpacer"></div>
        </div>
      </div>
    </div>
    <div>
      <div class="page_padding">
        <div class="ci" style="padding:4rem 0">
          <div class="text-center">
            <span class="fontExtraBold text333 font41">CI/BI</span>
          </div>
          <div class="verticalSpacer"></div>
          <div class="ci_main">
            <div class="text-left">
              <div>
                <span class="fontExtraBold text333 font20">스프링소프트</span>
              </div>
              <div style="padding: 1.5rem 0">
                <span class="word_break line-height2"
                  >따뜻한 봄날같은 소프트웨어라는 비전을 상징하는 벚꽃잎을
                  형상화 하고 벚꽃잎과 같은 폰트의 색을 표현하였습니다.
                </span>
              </div>
            </div>
            <div style="padding-bottom: 2rem">
              <div class="d-flex">
                <div class="imgWrap width50" style="margin-right:1rem">
                  <img src="../../assets/source/images/company_logo1.jpg" />
                </div>
                <div class="imgWrap width50">
                  <img src="../../assets/source/images/company_logo2.jpg" />
                </div>
              </div>
            </div>
            <div class="text-left">
              <div>
                <span class="fontExtraBold text333 font20">해피테이블</span>
              </div>
              <div style="padding: 1.5rem 0">
                <span class="word_break line-height2"
                  >해피테이블의 이미지를 이니셜 H와 테이블 그리고 클로버 잎을
                  기본 모티브로 하여 행복을 상징하는 클로버 잎을 사람으로
                  형상화하면서 테이블을 둘러싼 이미지로 표현하였습니다.</span
                >
              </div>
            </div>
            <div class="bgBackground" style="margin-bottom:1rem">
              <div class="imgWrap happytable_logo">
                <img
                  src="../../assets/source/images/company_happytablebi.jpg"
                />
              </div>
            </div>
            <div class="d-flex">
              <div class="imgWrap width50" style="margin-right:1rem">
                <img src="../../assets/source/images/company_logo3.jpg" />
              </div>
              <div class="imgWrap width50">
                <img src="../../assets/source/images/company_logo4.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page_padding bgBackground">
        <div class="paddingTB">
          <div class="d-flex word_break text-left flex-direction">
            <div style="margin-right:auto;" class="platform-head">
              <span class="line-height3 fontM text333"
                >환자와 가족 삶의 질 향상에 기여할 수 있는 플랫폼 구축
              </span>
            </div>
            <div
              style="margin-left:auto; margin-right:auto;"
              class="platform-sub"
            >
              <span class="line-height2 fontS">
                글로벌한 인지능력 향상 기술 개발 및 보유 기업으로 성장함과
                동시에 사용자 지향형“ICT융합 인지능력 향상
                프로젝트-해피테이블”의 사업화로 전 세계적으로 건전한 고령화 사회
                확립에 기여, 디지털 기반의 “시니어 여가 활동 증진＂이라는 신
                시장을 창출하여 스마트 헬스케어 시장에 미래 지향형 투자 생태계를
                마련함과 동시에 디지털 치료제로의 사업 방향성으로
                나아가겠습니다.
              </span>
            </div>
          </div>
          <div class="paddingTop4">
            <content-block
              v-bind:contents="businessContent['info']"
              extend="jpg"
            >
            </content-block>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsonContents from "../../assets/source/json/sub_top.json";
import CompanyHistory from "../../assets/source/json/CompanyHistory.json";
import BusinessContent from "../../assets/source/json/business_content.json";
import SubHead from "../../components/sub_head.vue";
import ContentBlock from "../../components/content_block.vue";

export default {
  components: {SubHead, ContentBlock},
  data() {
    return {
      content: jsonContents,
      companyHistory: CompanyHistory,
      businessContent: BusinessContent[0],
    };
  },
};
</script>

<style scoped>
.imgWrap {
  border-radius: 10px;
}
.imgWrap img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}
.padding2-5 {
  padding: 2.5rem 0;
}
.header_image {
  background-color: pink;
  height: 100px;
}
.header_image div {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paddingTB1-5 {
  padding: 1.5rem 0;
}

.history_background {
  background-image: url("../../assets/source/images/company_historybg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.year_title::before {
  content: "";
  background-image: url("../../assets/source/images/ic_history.png");
  width: 30px;
  height: 30px;
  position: absolute;
  left: calc(50% - 8px);
  margin-top: 8px;
}
.history .history_body {
  margin-left: 50%;
  padding-left: 4rem;
}
.history_reverse .history_body {
  margin-right: 50%;
  padding-right: 4rem;
}
.history {
  text-align: left;
}
.history_reverse {
  text-align: right;
}

.history_reverse .history_content {
  flex-direction: row-reverse;
}
.history_content div:nth-child(2n) {
  margin: 0 1rem;
}
.platform-head {
  width: 40%;
}

.platform-sub {
  width: 50%;
}
.paddingTop4 {
  padding-top: 4rem;
}
@media (min-width: 1281px) and (max-width: 1500px) {
  .paddingTB1 {
    padding-bottom: 0.8rem;
    padding-top: 0;
  }
  .font41 {
    font-size: 38px;
  }
  span.fontM {
    line-height: 2.5rem;
  }
  .fontM {
    font-size: 1.7rem;
  }
  .padding2-5 {
    padding: 1.5rem 0;
  }
  .info_img {
    width: 50%;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .padding2-5 {
    padding: 1rem 0;
  }
  .paddingTB1 {
    padding: 0.8rem 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .padding2-5 {
    padding: 0.8rem 0;
  }
  .paddingTB1 {
    padding: 0.6rem 0;
  }
}
@media (max-width: 767px) {
  .company_info.d-flex {
    flex-direction: column;
  }
  .info_text.width45 {
    width: 100%;
  }
  .info_img.width45 {
    width: 100%;
    height: 100%;
    margin: 1rem auto;
  }
  .padding2-5 {
    padding: 1rem 0;
  }
  .history-vertical-line img {
    display: none;
  }
  .year_title::before {
    background-image: none;
  }
  .history_body {
    margin: 0 !important;
    padding: 0 0 0 4rem !important;
    text-align: left;
  }
  .history_reverse .history_content {
    flex-direction: row;
  }
  .history_header {
    padding: 0.5rem 0;
    width: 100%;
  }
  .paddingTB1-5 {
    padding: 1rem 0;
  }
  .verticalSpacer {
    height: 0;
  }
  .flex-direction {
    flex-direction: column;
  }
  .platform-head {
    width: 100%;
    padding: 1rem 0;
  }
  .platform-sub {
    width: 100%;
  }
  .ci.d-flex {
    flex-direction: column;
  }
  .width60 {
    width: 100%;
  }
  .ci_main {
    margin-top: 2rem;
    width: 100%;
  }
  .paddingTop4 {
    padding-top: 2rem;
  }
}
</style>
