<template>
  <div
    class="d-flex justify-content-between text-left word_break flex-direction"
  >
    <div v-for="(sub, index) in contents" :key="index" class="block bgWhite">
      <img :src="getImgSrc(contents[index].img_src, extend)" />
      <div class="padding">
        <div class="paddingBottom">
          <span class="fontBold font18 text333">
            {{ contents[index].main }}
          </span>
        </div>
        <span class="font14" v-html="contents[index].sub"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contents: Array,
    extend: String,
  },
  methods: {
    getImgSrc(src, extend) {
      var images;
      if (extend == "jpg") {
        images = require.context("../assets/source/images/", false, /\.jpg$/);
        return images("./" + src + ".jpg");
      } else {
        images = require.context("../assets/source/images/", false, /\.png$/);
        return images("./" + src + ".png");
      }
    },
  },
};
</script>

<style scoped>
.block {
  width: 31%;
  border: 1px solid #d4d3d3;
  border-radius: 28px 0 28px 0;
}
.block img {
  width: 100%;
  border-radius: 28px 0 0 0;
}
.padding {
  padding: 1.5rem;
}
.paddingBottom {
  padding-bottom: 0.5rem;
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .padding {
    padding: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .padding {
    padding: 0.8rem;
  }
}
@media (max-width: 767px) {
  .flex-direction {
    flex-direction: column;
  }
  .block {
    width: 100%;
    margin: 1rem 0;
  }
}
</style>
