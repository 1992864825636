<template>
  <div class="top" :style="{backgroundImage: 'url(' + getImgSrc + ')'}">
    <span class="fontExtraBold textWhite font34 marginAutoAll">
      {{ subTitle.sub_title }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    subTitle: Object,
  },
  computed: {
    getImgSrc: function() {
      var images = require.context("../assets/source/images/", false, /\.jpg$/);
      return images("./" + this.getImgName() + ".jpg");
    },
  },
  methods: {
    getImgName() {
      if (!this.checkMobileWidth()) {
        return "m_" + this.subTitle.img_name;
      }
      return this.subTitle.img_name;
    },
    checkMobileWidth() {
      return window.innerWidth > 767;
    },
  },
};
</script>

<style>
@import url("../assets/source/css/style.css");
.top {
  height: 280px;
  display: flex;
  align-items: center;
  padding-top: 64px;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .top {
    height: 230px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .top {
    height: 200px;
  }
}
@media (max-width: 767px) {
  .top {
    height: 150px;
  }
}
</style>
